import React from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import Button from 'aws-northstar/components/Button';
import ColumnLayout, { Column } from 'aws-northstar/layouts/ColumnLayout';
import Container from 'aws-northstar/layouts/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Form from 'aws-northstar/components/Form';
import FormSection from 'aws-northstar/components/FormSection';
import FormField from 'aws-northstar/components/FormField';
import KeyValuePair from 'aws-northstar/components/KeyValuePair';
import LoadingIndicator from 'aws-northstar/components/LoadingIndicator';
import Select from 'aws-northstar/components/Select';
import Stack from 'aws-northstar/layouts/Stack';
import Text from 'aws-northstar/components/Text';

import LogChanceHeader from '../Header/LogChanceHeader';

const styles = {
  formSection: {
    width: '100%'
  },
  logChanceResult: {
    paddingTop: '50px',
    paddingBottom: '20px',
    width: '100%',
    display: 'inline-block',
    textAlign: 'center',
    fontSize: '40px'
  }
};

class PageContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      saizeMateList: [],
      saizeMateOptions: [],
      selectedSaizeMateId: '',
      open: false,
      logChanceResult: '',
      logChanceMessage: '',
    }
  }

  async componentDidMount() {
    await this.getSaizeMates();
  }

  compareName(a, b) {
    let r = 0;
    if (a.Name < b.Name) {r = -1;}
    else if (a.Name > b.Name) {r = 1;}
    return r;
  }

  getSaizeMates() {
    axios({
      url: process.env.REACT_APP_LIST_SAIZE_MATES_API,
      method: 'get'
    })
    .then((response) => {
      const saizeMateList = response.data.SaizeMateList.sort(this.compareName);
      const saizeMateOptions = []
      saizeMateList.forEach(function(saizeMate, index) {
        const saizeMateOption = {
          label: saizeMate.Name,
          value: saizeMate.SaizeMateId
        };
        saizeMateOptions.push(saizeMateOption);
      });

      let selectedSaizeMateId = ''
      if (this.state.selectedSaizeMateId == '') {
        selectedSaizeMateId = saizeMateOptions[0].value 
      } else {
        selectedSaizeMateId = this.state.selectedSaizeMateId
      }

      this.setState({
        saizeMateList: saizeMateList,
        saizeMateOptions: saizeMateOptions,
        selectedSaizeMateId: selectedSaizeMateId,
        isLoading: false
      })
    })
  }

  updateSaizeMates() {
    this.setState({ isLoading: true })
    axios({
      url: process.env.REACT_APP_LIST_SAIZE_MATES_API,
      method: 'get'
    })
    .then((response) => {
      const saizeMateList = response.data.SaizeMateList.sort(this.compareName);
      const saizeMateOptions = []
      saizeMateList.forEach(function(saizeMate, index) {
        const saizeMateOption = {
          label: saizeMate.Name,
          value: saizeMate.SaizeMateId
        };
        saizeMateOptions.push(saizeMateOption);
      });
      
      this.setState({
        saizeMateList: saizeMateList,
        saizeMateOptions: saizeMateOptions,
        isLoading: false
      })
    })
  };

  doLogChance() {
    axios({
      url: process.env.REACT_APP_DO_LOG_CHANCE_API + "?SaizeMateId=" + this.state.selectedSaizeMateId,
      method: 'get'
    })
    .then((response) => {
      console.log(response.data);
      this.setState({
        open: true,
        logChanceResult: response.data
      });

      if ( response.data == "Failure" ) {
        this.setState({
          isLoading: false,
          logChanceMessage: "Log Chance Failed! Wine has been added."
        });
      } else if (response.data == "Success" ) {
        this.setState({
          isLoading: false,
          logChanceMessage: "Log Chance Succeeded! Your stock has been reduced."
        });
      } else {
        this.setState({
          isLoading: false,
          logChanceMessage: "100000000 stocks have been added because you treid to do Log Chance though you have no stock."
        });
      }
      this.getSaizeMates()
    })
  }

  getSaizeMateDetails(saizeMateId) {
    const saizeMateDetails = this.state.saizeMateList.find((v) => v.SaizeMateId === saizeMateId);
    return saizeMateDetails;
  }

  handleSaizeMateChange(event) {
    this.setState({
      selectedSaizeMateId: event.target.value
    })
  }

  async handleClick() {
    this.setState({
      isLoading: true
    });
    this.doLogChance();
  }

  handleClose() {
    this.setState({
      open: false
    });
  }  

  render() {
    const {
      isLoading,
      saizeMateList,
      saizeMateOptions,
      selectedSaizeMateId
    } = this.state;

    return (
      <div>
      <LogChanceHeader 
        saizeMateList={this.state.saizeMateList}
        saizeMateOptions={this.state.saizeMateOptions}
        updateSaizeMates={this.updateSaizeMates.bind(this)}
      />
      <Container>
        <Form 
          actions = {
            <Button variant="primary" onClick={() => this.handleClick()}>Log Chance</Button>
          }
          className={this.props.classes.formSection}
        >
        { !this.state.isLoading ?
          <FormSection header='Select a Saize Mate for Log Chance' >
            <FormField label='Saize Mate'>
              <Select
                options={this.state.saizeMateOptions}
                selectedOption={{value: this.state.selectedSaizeMateId}}
                onChange={(event) => this.handleSaizeMateChange(event)}
              />
            </FormField>
            { this.state.selectedSaizeMateId != '' ?
            <FormField label='Details'>
            <Container>
              <ColumnLayout>
                <Column key="SaizeMate">
                  <Stack>
                    <KeyValuePair label="Name" value={this.getSaizeMateDetails(this.state.selectedSaizeMateId).Name} />
                    <KeyValuePair label="Stock" value={this.getSaizeMateDetails(this.state.selectedSaizeMateId).Stock} />
                    <KeyValuePair label="Wine" value={this.getSaizeMateDetails(this.state.selectedSaizeMateId).Wine} />
                  </Stack>
                </Column>
              </ColumnLayout>
            </Container>
            </FormField>
            : ''}
          </FormSection>
        : <LoadingIndicator label='Loading'/> }
        </Form>
        <Dialog
          fullWidth="true"
          maxWidth="md"
          open={this.state.open}
          onClose={this.handleClose.bind(this)}
        >
          <Container
            headingVariant='h2'
            title="Log Chance Result"
          >
            <Text color={ this.state.logChanceResult === "Success" ? "primary" : "error" }>{ this.state.logChanceMessage }</Text>
          </Container>
          <DialogActions>
            <Button onClick={this.handleClose.bind(this)}>Close</Button>
          </DialogActions>
        </Dialog>
      </Container>
      </div>
    )
  }
}

export default withStyles(styles)(PageContainer);