import React from 'react';
import axios from 'axios';
import Box from 'aws-northstar/layouts/Box';
import Button from 'aws-northstar/components/Button';
import ButtonDropdown from 'aws-northstar/components/ButtonDropdown';
import Container from 'aws-northstar/layouts/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Form from 'aws-northstar/components/Form';
import FormSection from 'aws-northstar/components/FormSection';
import FormField from 'aws-northstar/components/FormField';
import Header from 'aws-northstar/components/Header';
import Input from 'aws-northstar/components/Input';
import LoadingIndicator from 'aws-northstar/components/LoadingIndicator';
import Select from 'aws-northstar/components/Select';
import Text from 'aws-northstar/components/Text';

const LogChanceHeader = ({ saizeMateList, saizeMateOptions, updateSaizeMates }) => {
    const [action, setAction] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [numStock, setNumStock] = React.useState("");
    const [numWine, setNumWine] = React.useState("");
    const [saizeMateId, setSaizeMateId] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [newSaizeMate, setNewSaizeMate] = React.useState("");

    const menuItems = [
        { text: 'Add Stock', onClick: () => { setAction("AddStock"); handleClickOpen(); }},
        { text: 'Reduce Wine', onClick: () => { setAction("ReduceWine"); handleClickOpen(); }},
        { text: 'Add Member', onClick: () => { setAction("AddMember"); handleClickOpen(); }},
        { text: 'Remove Member', onClick: () => { setAction("RemoveMember"); handleClickOpen(); }}
    ]

    const rightContent = (
        <Box display="flex" alignItems='center'>
            <ButtonDropdown content='Action' items={menuItems} darkTheme />
        </Box>
    )

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setAction("");
        setMessage("");
        setOpen(false);
    };

    const handleSaizeMateChange = (event) => {
        setSaizeMateId(event.target.value);
    }

    const handleNumStock = (value) => {
        console.log(numStock)
        setNumStock(value);
    }

    const handleNumWine = (value) => {
        setNumWine(value);
    }

    const handleNewSaizeMate = (value) => {
        setNewSaizeMate(value);
    }

    const updateStock = () => {
        setIsLoading(true);

        axios({
            url: process.env.REACT_APP_UPDATE_STOCK_API + "?SaizeMateId=" + saizeMateId + "&StockNum=" + numStock + "&StockMethod=Add",
            method: 'get'
        })
        .then((response) => {
            setSaizeMateId("");
            setNumStock("");
            setIsLoading(false);
            setMessage("Stock added.");
            updateSaizeMates();
        })
    }

    const updateWine = () => {
        setIsLoading(true);

        axios({
            url: process.env.REACT_APP_UPDATE_WINE_API + "?SaizeMateId=" + saizeMateId + "&WineNum=" + numWine + "&WineMethod=Reduce",
            method: 'get'
        })
        .then((response) => {
            setSaizeMateId("");
            setNumWine("");
            setIsLoading(false);
            setMessage("Reduced wine.")
            updateSaizeMates();
        })
    }

    const addNewSaizeMate = () => {
        setIsLoading(true);
        
        axios({
            url: process.env.REACT_APP_ADD_SAIZE_MATE_API + "?Name=" + newSaizeMate,
            method: 'get' 
        })
        .then((response) => {
            setNewSaizeMate("");
            setIsLoading(false);
            setMessage("A new Saize Mate added.");
            updateSaizeMates();
        })
    }

    const removeSaizeMate = () => {
        setIsLoading(true);

        axios({
            url: process.env.REACT_APP_REMOVE_SAIZE_MATE_API + "?SaizeMateId=" + saizeMateId
        })
        .then((response) => {
            setSaizeMateId("")
            setIsLoading(false);
            setMessage("The selected Saize Mate removed.");
            updateSaizeMates();
        })
    }

    const renderElement = () => {
        if ( action == "" ) {
            return ""
        } else if (action == "AddStock") {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    open={open}
                    onClose={handleClose}
                >
                    { !isLoading ? 
                    <Container
                        headingVariant='h2'
                        title="Add Stock"
                    >
                        <Form>
                            <FormSection header='Add stock to a selected Saize Mate'>
                                <FormField label='Saize Mate'>
                                    <Select 
                                        options={saizeMateOptions}
                                        selectedOption={{value: saizeMateId}}
                                        onChange={(event) => handleSaizeMateChange(event)}
                                    />
                                </FormField>
                                <FormField label='The number of stocks to add'>
                                    <Input 
                                        type='text'
                                        placeholder="i.g. 100000000"
                                        value={numStock}
                                        controlId="numStock"
                                        onChange={(value) => handleNumStock(value)}
                                    />
                                </FormField>
                            </FormSection>
                        </Form>
                        <Text>{ message }</Text>
                    </Container>
                    : <LoadingIndicator label='Loading'/> }
                    <DialogActions>
                        <Button 
                            onClick={updateStock}
                            disabled={saizeMateId == "" || numStock == "" || isNaN(Number.parseInt(numStock, 10)) || Number.parseInt(numStock, 10) < 0 || String(Number.parseInt(numStock, 10)) != numStock }
                        >
                            Add Stock
                        </Button>
                        <Button onClick={handleClose}>Close</Button> 
                    </DialogActions>
                </Dialog>
            )
        } else if (action == "ReduceWine") {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    open={open}
                    onClose={handleClose}
                >
                    { !isLoading ? 
                    <Container
                        headingVariant='h2'
                        title="Reduce Wine"
                    >
                        <Form>
                            <FormSection header='Reduce wine from a selected Saize Mate'>
                                <FormField label='Saize Mate'>
                                    <Select 
                                        options={saizeMateOptions}
                                        selectedOption={{value: saizeMateId}}
                                        onChange={(event) => handleSaizeMateChange(event)}
                                    />
                                </FormField>
                                <FormField label='The number of wines to reduce'>
                                    <Input 
                                        type='text'
                                        placeholder="i.g. 1"
                                        value={numWine}
                                        controlId="numWine"
                                        onChange={(value) => handleNumWine(value)}
                                    />
                                </FormField>
                            </FormSection>
                        </Form>
                        <Text>{ message }</Text>
                    </Container>
                    : <LoadingIndicator label='Loading'/> }
                    <DialogActions>
                        <Button 
                            onClick={updateWine}
                            disabled={saizeMateId == "" || numWine == "" || isNaN(Number.parseInt(numWine, 10)) || Number.parseInt(numWine, 10) < 0 || String(Number.parseInt(numWine, 10)) != numWine }
                        >
                            Reduce Wine
                        </Button>
                        <Button onClick={handleClose}>Close</Button> 
                    </DialogActions>
                </Dialog>
            )
        } else if (action == "AddMember") {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    open={open}
                    onClose={handleClose}
                >
                    { !isLoading ?
                    <Container
                        headingVariant='h2'
                        title="Add Member"
                    >
                        <Form>
                            <FormSection header='Add a new Saize Mate'>
                                <FormField label='New Saize Mate'>
                                    <Input
                                        type='text'
                                        placeholder="i.g. genkit"
                                        value={newSaizeMate}
                                        controlId="newSaizeMate"
                                        onChange={(value) => handleNewSaizeMate(value)}
                                    />
                                </FormField>
                            </FormSection>
                        </Form>
                        <Text>{ message }</Text>
                    </Container>
                    : <LoadingIndicator label='Loading' /> }
                    <DialogActions>
                        <Button
                            onClick={addNewSaizeMate}
                            disabled={newSaizeMate == ""}
                        >
                            Add Saize Mate
                        </Button>
                        <Button onClick={handleClose}>Close</Button> 
                    </DialogActions>
                </Dialog>
            )
        } else if (action == "RemoveMember") {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    open={open}
                    onClose={handleClose}
                >
                    { !isLoading ? 
                    <Container
                        headingVariant='h2'
                        title="Remove Member"
                    >
                        <Form>
                            <FormSection header='Remove a Saize Mate'>
                                <FormField label='Saize Mate'>
                                    <Select 
                                        options={saizeMateOptions}
                                        selectedOption={{value: saizeMateId}}
                                        onChange={(event) => handleSaizeMateChange(event)}
                                    />
                                </FormField>
                            </FormSection>
                        </Form>
                        <Text>{ message }</Text>
                    </Container>
                    : <LoadingIndicator label='Loading'/> }
                    <DialogActions>
                        <Button 
                            onClick={removeSaizeMate}
                            disabled={saizeMateId == ""}
                        >
                            Remove Saize Mate
                        </Button>
                        <Button onClick={handleClose}>Close</Button> 
                    </DialogActions>
                </Dialog>
            )
        }
    }

    return (
        <div>
            <Header title='Log Chance' rightContent={rightContent}/>
            { renderElement() }
        </div>
    )
};

export default LogChanceHeader;